body {
  font-family: "GT Walsheim", "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.header {
  letter-spacing: 2;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.customflexvertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.sc-bZQynM.jrBYqH {
  justify-content: center;
}

.site-background {
  background: linear-gradient(to right, #000, #0d47a1);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#bbdefb),
    to(#e3f2fd)
  );
}

.customflex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.customcenteralign {
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.no-style-link a {
  outline: 0;
  text-decoration: none;
  color: #ffffff;
} /* Globally */
.no-style-link a:visited {
  outline: 0;
  text-decoration: none;
  color: #ffffff;
}
.no-style-link a:hover {
  outline: 0;
  text-decoration: none;
  color: #ffffff;
}
.no-style-link a:focus {
  outline: 0;
  text-decoration: none;
  color: #ffffff;
}
.no-style-link a:hover,
a:active {
  outline: 0;
  text-decoration: none;
  color: #ffffff;
}

a.no-style-link {
  outline: 0;
  text-decoration: none;
  color: #ffffff;
} /* Globally */

/* Each state */

a.no-style-link:visited {
  outline: 0;
  text-decoration: none;
  color: #ffffff;
}
a.no-style-link:hover {
  outline: 0;
  text-decoration: none;
  color: #ffffff;
}
a.no-style-link:focus {
  outline: 0;
  text-decoration: none;
  color: #ffffff;
}
a.no-style-link:hover,
a.no-style-link:active {
  outline: 0;
  text-decoration: none;
  color: #ffffff;
}
